import { User } from "firebase/auth"
import { auth } from "../../firebase-config"
import { ENABLE_ERROR_LOGGING } from "../settings/project-settings"


export const getCustomClaims = async () => {
    return (await auth.currentUser?.getIdTokenResult())?.claims
}

export const getUserIsCreatedData = async () => {
    return (await auth.currentUser?.getIdTokenResult())?.claims.createdData
}

export const getUserIsPaid = async () => {
    const tier = (await auth.currentUser?.getIdTokenResult())?.claims.paid
    if( ENABLE_ERROR_LOGGING ) console.log("is paid tier: " + tier)
    return tier > 0 
}

export const getUserPaymentTier = async () => {
    const tier = (await getCustomClaims() as any).paid
    return tier
}

export const getUserEmail = () => {
    const currentUser = getCurrentUser()
    return currentUser ? currentUser.email as string : ""
}

export const getCurrentUser = () => {
    if(auth.currentUser) return auth.currentUser as User
    return false
}

export const getUIDMain = () => {
    const currentUser : any = getCurrentUser()
    const hasCurrentUser : boolean =  currentUser !== false
    if(hasCurrentUser) return currentUser.uid
    return undefined
}

export const getUserVerified = () => {
    const currentUser : any = getCurrentUser()
    const hasCurrentUser : boolean =  currentUser !== false
    if(hasCurrentUser) return currentUser.emailVerified
    return undefined
}


export const getUserToken = async () => {
    const currentUser : any = getCurrentUser()
    const hasCurrentUser : boolean =  currentUser !== false
    if(hasCurrentUser) return await currentUser.getIdToken()
    return undefined
}

